<template>
  <div>
    <Header ref="header"/>

    <div class="homeindex">
      <div class="content" style="min-height: 900px;">
        <div style="height: 30px"></div>
        <div class="visual-item w1200" style="display: block;">

          <div class="view" >
<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="success"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="info"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

<!--            <el-alert-->
<!--                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"-->
<!--                type="warning"-->
<!--                effect="dark" style="margin-bottom: 20px">-->
<!--            </el-alert>-->

            <el-alert
                title="本页面促销活动仅为公司促销的一小部分品种，部分品种存在限销，部分品种促销只针对指定客户，详细政策请看品种详情页，以公司实际开票为准。"
                type="error"
                effect="dark" style="margin-bottom: 20px" >
            </el-alert>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[0].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[0].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[1].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[1].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[2].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[2].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[3].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[3].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[4].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[4].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[5].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[5].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[6].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[6].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[7].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[7].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[8].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[8].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[9].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[9].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[10].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[10].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[11].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[11].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[12].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[12].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[13].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[13].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[14].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[14].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[15].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[15].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[16].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[16].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[17].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[17].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[18].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[18].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[19].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[19].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>

          <div class="view">
            <div class="store-channel">
              <div class="rec-store-list">
                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[20].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[20].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[21].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[21].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[22].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[22].url"></div>
                  </router-link>
                </div>

                <div class="rec-store-item opacity_img">
                  <router-link :to="{path:'/goods',query:{id:Floor[23].id}}" target="_blank"  tag="a">
                    <div class="p-img"><img :src="Floor[23].url"></div>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="visual-item w1200" style="display: block;" ectype="visualItme" data-li="1" data-purebox="homeFloor" data-mode="homeFloor" data-diff="4">

          <div class="view">
            <div class="floor-content">
              <div class="floor-line-con floorOne floor-color-type-1">
                <div class="floor-bd bd-mode-01">
                  <div class="bd-left">
                    <div class="floor-left-slide">
                      <div class="bd">
                        <ul>
                          <li>
                            <a href="javascript:void(0);">
                              <img width="232" height="570" :src="Floor[24].url">
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="hd" style="display: none;"><ul></ul></div>
                    </div>
                    <div class="floor-left-adv">
                      <router-link :to="{path:'/goods',query:{id:Floor[25].id}}" target="_blank" tag="a" class="mb10">
                        <img width="232" height="280" :src="Floor[25].url">
                      </router-link>
                      <router-link :to="{path:'/goods',query:{id:Floor[26].id}}" target="_blank" tag="a" class="mb10">
                        <img width="232" height="280" :src="Floor[26].url">
                      </router-link>
                    </div>
                  </div>
                  <div class="bd-right">
                    <div class="floor-tabs-content clearfix">
                      <div class="f-r-main f-r-m-adv">
                        <div class="f-r-m-item">
                          <router-link :to="{path:'/goods',query:{id:Floor[27].id}}" target="_blank" tag="a">
                            <img :src="Floor[27].url">
                          </router-link>
                        </div>
                        <div class="f-r-m-item">
                          <router-link :to="{path:'/goods',query:{id:Floor[28].id}}" target="_blank" tag="a">
                            <img :src="Floor[28].url">
                          </router-link>
                        </div>
                        <div class="f-r-m-item">
                          <router-link :to="{path:'/goods',query:{id:Floor[29].id}}" target="_blank" tag="a">
                            <img :src="Floor[29].url">
                          </router-link>
                        </div>
                        <div class="f-r-m-item">
                          <router-link :to="{path:'/goods',query:{id:Floor[30].id}}" target="_blank" tag="a">
                            <img :src="Floor[30].url">
                          </router-link>
                        </div>
                        <div class="f-r-m-item f-r-m-i-double">
                          <router-link :to="{path:'/goods',query:{id:Floor[31].id}}" target="_blank" tag="a">
                            <img :src="Floor[31].url">
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/header/Index'
import Footer from '@/components/footer/Index'
export default {
  name: "Promotion",
  components: {
    Header,
    Footer
  },
  mounted() {
    this.$refs.header.hideCate();
  },
  data() {
    return {
      Floor:[
        {'index':0,'url':'http://admin.jzybyy.com/static/promotion/1008656.png','id':'10222','search':''},     //复方利血平氨苯蝶啶片
        {'index':1,'url':'http://admin.jzybyy.com/static/promotion/1002047.png','id':'5568','search':''},      //安神补脑液
        {'index':2,'url':'http://admin.jzybyy.com/static/promotion/1005741.png','id':'8386','search':''},      //苯磺酸氨氯地平片
        {'index':3,'url':'http://admin.jzybyy.com/static/promotion/1008168.png','id':'9953','search':''},      //金振口服液

        {'index':4,'url':'http://admin.jzybyy.com/static/promotion/1033945.png','id':'16204','search':''},
        {'index':5,'url':'http://admin.jzybyy.com/static/promotion/1033946.png','id':'16205','search':''},
        {'index':6,'url':'http://admin.jzybyy.com/static/promotion/1033947.png','id':'16206','search':''},
        {'index':7,'url':'http://admin.jzybyy.com/static/promotion/1034168.png','id':'16207','search':''},

        {'index':8,'url':'http://admin.jzybyy.com/static/promotion/1000855.png','id':'4685','search':''},       //复方酮康唑发用洗剂
        {'index':9,'url':'http://admin.jzybyy.com/static/promotion/1004873.png','id':'7797','search':''},       //头孢地尼分散片
        {'index':10,'url':'http://admin.jzybyy.com/static/promotion/1002170.png','id':'5669','search':''},      //金振口服液
        {'index':11,'url':'http://admin.jzybyy.com/static/promotion/1004852.png','id':'7780','search':''},      //头孢地尼分散片

        {'index':12,'url':'http://admin.jzybyy.com/static/promotion/1013221.png','id':'12686','search':''},
        {'index':13,'url':'http://admin.jzybyy.com/static/promotion/1031518.png','id':'16100','search':''},
        {'index':14,'url':'http://admin.jzybyy.com/static/promotion/1032203.png','id':'16481','search':''},
        {'index':15,'url':'http://admin.jzybyy.com/static/promotion/1012216.png','id':'12191','search':''},     //三七粉

        {'index':16,'url':'http://admin.jzybyy.com/static/promotion/1011579.png','id':'11873','search':''},
        {'index':17,'url':'http://admin.jzybyy.com/static/promotion/1000885.png','id':'4710','search':''},      //儿童益生菌冲剂(合生元)
        {'index':18,'url':'http://admin.jzybyy.com/static/promotion/1004975.png','id':'7878','search':''},      //碧生源牌常润茶
        {'index':19,'url':'http://admin.jzybyy.com/static/promotion/1016049.png','id':'14289','search':''},

        {'index':20,'url':'http://admin.jzybyy.com/static/promotion/1010348.png','id':'11201','search':''},
        {'index':21,'url':'http://admin.jzybyy.com/static/promotion/1032770.png','id':'15338','search':''},
        {'index':22,'url':'http://admin.jzybyy.com/static/promotion/1007218.png','id':'9363','search':''},
        {'index':23,'url':'http://admin.jzybyy.com/static/promotion/1013229.png','id':'12690','search':''},

        {'index':24,'url':'http://admin.jzybyy.com/static/promotion/song.png','id':'','search':''},
        {'index':25,'url':'http://admin.jzybyy.com/static/promotion/1005970.png','id':'8538','search':''},
        {'index':26,'url':'http://admin.jzybyy.com/static/promotion/1007640.png','id':'9633','search':''},
        {'index':27,'url':'http://admin.jzybyy.com/static/promotion/1010791.png','id':'11434','search':''},
        {'index':28,'url':'http://admin.jzybyy.com/static/promotion/1011842.png','id':'12023','search':''},
        {'index':29,'url':'http://admin.jzybyy.com/static/promotion/1013792.png','id':'12950','search':''},
        {'index':30,'url':'http://admin.jzybyy.com/static/promotion/1010794.png','id':'11436','search':''},
        {'index':31,'url':'http://admin.jzybyy.com/static/promotion/1014942.png','id':'13529','search':''},
      ],
    }
  }
}
</script>

<style scoped>
.rec-store-item .p-img {
  height: 381px;
}
</style>
